import React from "react";
import Layout from "../components/Layout/Layout";
import SEO from "../components/seo";
import styled from "styled-components";
import coa from "../images/pain-cream-coa-9-2020.jpg";
import logo from "../images/evolve-organix-logo.svg";
import { Link } from "gatsby";

const PainCreamCOAPage = () => {
  return (
    <Layout>
      <SEO title="Pain Cream Certificate of Analysis" />
      <Wrapper>
        <LogoContainer>
          <Link to="/">
            <Logo src={logo} />
          </Link>
        </LogoContainer>
        <ImageWrapper>
          <Img src={coa} alt="Pain Cream Certificate of Analysis" />
        </ImageWrapper>
      </Wrapper>
    </Layout>
  );
};

export default PainCreamCOAPage;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
  flex-direction: column;
`;

const LogoContainer = styled.div`
  padding: 0 64px;
  position: relative;
  width: 100%;
  max-width: 30rem;
  height: auto;
  margin-bottom: 40px;
`;

const Logo = styled.img``;

const ImageWrapper = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 4rem;
`;

const Img = styled.img`
  max-width: 80rem;
  width: 100%;
`;
